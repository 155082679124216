import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Lightbox from './Lightbox'

const AlairConfImagesTwenty = () => (
  <StaticQuery
    query={graphql`
      query {
        conferenceImages: allFile(
          filter: { 
            sourceInstanceName: { eq: "confTwentyImages" }, 
            dir: { glob: "**/Conference"}
          }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
        eventImages: allFile(
          filter: { 
            sourceInstanceName: { eq: "confTwentyImages" }, 
            dir: { glob: "**/Special Event"}
          }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
  			teamImages: allFile(
          filter: { 
            sourceInstanceName: { eq: "confTwentyImages" }, 
            dir: { glob: "**/Team Building"}
          }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `}
    render={data => <div>
      <h2>Conference</h2>
      <Lightbox images={data.conferenceImages.edges} />
      <h2>Special Event</h2>
      <Lightbox images={data.eventImages.edges} />
      <h2>Team Building</h2>
      <Lightbox images={data.teamImages.edges} />
    </div>}
  />
)
export default AlairConfImagesTwenty
