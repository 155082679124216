import React from 'react'
import AlairConfImagesTwenty from '../../../components/AlairConfImagesTwenty'
import H1 from '../../../components/H1'
import Layout from '../../../components/Layout'

const acp2020 = () => (
  <Layout>
    <H1 title="ALAIR Conference Photos 2020" />
    <AlairConfImagesTwenty />
  </Layout>
)
export default acp2020
